var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yh_wrapper"},[_c('div',{staticClass:"formleft"},[_c('el-form',{ref:"form",staticClass:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"inline":true}},[_c('el-row',[_c('div',{staticClass:"title"},[_vm._v("录制回放")]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"bg"},[_c('el-form-item',{attrs:{"label":"显示点赞开关"}},[_c('el-switch',{staticClass:"switch",attrs:{"active-color":"#3F8CFF","inactive-color":"#ccc","active-value":"1","inactive-value":"0"},model:{value:(_vm.formData.liveInfo.liveZanState),callback:function ($$v) {_vm.$set(_vm.formData.liveInfo, "liveZanState", $$v)},expression:"formData.liveInfo.liveZanState"}})],1)],1)]),_c('div',{staticClass:"num"},[_vm._v("点赞数")]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"selet"},[_c('el-form-item',{attrs:{"prop":"liveInfo.liveZanShow","rules":{
                required: _vm.formData.liveInfo.liveZanState == 1,
                message: '请选择点赞数显示',
                trigger: 'change',
              }}},[_c('el-radio-group',{attrs:{"disabled":_vm.formData.liveInfo.liveZanState == 0},model:{value:(_vm.formData.liveInfo.liveZanShow),callback:function ($$v) {_vm.$set(_vm.formData.liveInfo, "liveZanShow", $$v)},expression:"formData.liveInfo.liveZanShow"}},[_c('el-radio',{attrs:{"label":1,"border":""}},[_vm._v("显示")]),_c('el-radio',{attrs:{"label":0,"border":""}},[_vm._v("不显示")])],1)],1)],1)]),_c('div',{staticClass:"title"},[_vm._v("增加形式")]),_c('el-col',{attrs:{"span":20}},[_c('div',{staticClass:"dobble"},[_c('el-form-item',{attrs:{"prop":"liveInfo.liveZanBaseNum","label":"默认基础人数","rules":{
                required: _vm.formData.liveInfo.liveZanShow == 1,
                message: '请填写默认点赞人数',
                trigger: 'blur',
              }}},[_c('el-input',{attrs:{"type":"number","min":"0","placeholder":"请填写默认点赞人数","disabled":_vm.formData.liveInfo.liveZanState == 0 ||
                  _vm.formData.liveInfo.liveZanShow == 0,"clearable":""},nativeOn:{"keydown":function($event){return _vm.channelInputLimit.apply(null, arguments)}},model:{value:(_vm.formData.liveInfo.liveZanBaseNum),callback:function ($$v) {_vm.$set(_vm.formData.liveInfo, "liveZanBaseNum", $$v)},expression:"formData.liveInfo.liveZanBaseNum"}}),_c('span',{staticClass:"dobble_span"},[_vm._v("人")])],1)],1)]),_c('el-col',{attrs:{"span":20}},[_c('div',{staticClass:"dobble"},[_c('el-form-item',{attrs:{"prop":"liveInfo.liveZanBaseTimes","rules":{
                required: _vm.formData.liveInfo.liveZanShow == 1,
                message: '请填写默认点赞倍数',
                trigger: 'change',
              },"label":"实际人数 × "}},[_c('el-select',{ref:"searchSelect",attrs:{"placeholder":"请选择实际点赞倍数","disabled":_vm.formData.liveInfo.liveZanState == 0 ||
                  _vm.formData.liveInfo.liveZanShow == 0,"clearable":"","filterable":""},nativeOn:{"input":function($event){return _vm.filterData.apply(null, arguments)}},model:{value:(_vm.formData.liveInfo.liveZanBaseTimes),callback:function ($$v) {_vm.$set(_vm.formData.liveInfo, "liveZanBaseTimes", $$v)},expression:"formData.liveInfo.liveZanBaseTimes"}},_vm._l((_vm.LIKE_MULTIPLE),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.label,"value":item.id}})}),1),_c('span',{staticClass:"dobble_span"},[_vm._v("倍")])],1)],1)])],1),_c('el-row',[_c('div',{staticClass:"attention"},[_c('span',[_vm._v("注意：")]),_c('br'),_c('span',[_vm._v("1、显示点赞人数=默认基础点赞数+实际点赞数 × 倍数")]),_c('br'),_c('span',[_vm._v("2、该数据只为前端数据展示，后台数据以真实数据统计")])])]),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"yh_submit"},[_c('el-button',{staticClass:"btn btn_blue",on:{"click":function($event){return _vm.plugFlowSubmit('form')}}},[_vm._v("提交")])],1)])],1)],1)],1),_c('div',{staticClass:"formright"},[_c('div',{staticClass:"title"},[_vm._v("预览效果")]),_c('div',{staticClass:"preview"},[_c('img',{attrs:{"src":require('@/assets/img/backStageImg/likepreview-icon.png'),"alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }