<template>
  <div class="yh_wrapper">
    <div class="formleft">
      <el-form
        class="form"
        :model="formData"
        :rules="rules"
        ref="form"
        :inline="true"
      >
        <el-row>
          <div class="title">录制回放</div>
          <el-col :span="24">
            <div class="bg">
              <el-form-item label="显示点赞开关">
                <el-switch
                  v-model="formData.liveInfo.liveZanState"
                  active-color="#3F8CFF"
                  inactive-color="#ccc"
                  active-value="1"
                  inactive-value="0"
                  class="switch"
                >
                </el-switch>
              </el-form-item>
            </div>
          </el-col>
          <div class="num">点赞数</div>
          <el-col :span="24">
            <div class="selet">
              <el-form-item
                prop="liveInfo.liveZanShow"
                :rules="{
                  required: formData.liveInfo.liveZanState == 1,
                  message: '请选择点赞数显示',
                  trigger: 'change',
                }"
              >
                <el-radio-group
                  v-model="formData.liveInfo.liveZanShow"
                  :disabled="formData.liveInfo.liveZanState == 0"
                >
                  <el-radio :label="1" border>显示</el-radio>
                  <el-radio :label="0" border>不显示</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>
          <div class="title">增加形式</div>
          <el-col :span="20">
            <div class="dobble">
              <el-form-item
                prop="liveInfo.liveZanBaseNum"
                label="默认基础人数"
                :rules="{
                  required: formData.liveInfo.liveZanShow == 1,
                  message: '请填写默认点赞人数',
                  trigger: 'blur',
                }"
              >
                <!-- <el-checkbox
                  v-model="formData.num"
                  :disabled="
                    formData.liveInfo.liveZanState == 0 || formData.liveInfo.liveZanShow == 0
                  "
                  class="checkbox"
                ></el-checkbox> -->
                <el-input
                  v-model="formData.liveInfo.liveZanBaseNum"
                  type="number"
                  min="0"
                  placeholder="请填写默认点赞人数"
                  :disabled="
                    formData.liveInfo.liveZanState == 0 ||
                    formData.liveInfo.liveZanShow == 0
                  "
                  clearable
                  @keydown.native="channelInputLimit"
                ></el-input>
                <span class="dobble_span">人</span>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="dobble">
              <el-form-item
                prop="liveInfo.liveZanBaseTimes"
                :rules="{
                  required: formData.liveInfo.liveZanShow == 1,
                  message: '请填写默认点赞倍数',
                  trigger: 'change',
                }"
                label="实际人数 × "
              >
                <!-- <el-checkbox
                  v-model="formData.times"
                  :disabled="
                    formData.liveInfo.liveZanState == 0 || formData.liveInfo.liveZanShow == 0
                  "
                  class="checkbox"
                ></el-checkbox> -->
                <el-select
                  v-model="formData.liveInfo.liveZanBaseTimes"
                  placeholder="请选择实际点赞倍数"
                  :disabled="
                    formData.liveInfo.liveZanState == 0 ||
                    formData.liveInfo.liveZanShow == 0
                  "
                  clearable
                  filterable
                  @input.native="filterData"
                  ref="searchSelect"
                >
                  <el-option
                    v-for="item in LIKE_MULTIPLE"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span class="dobble_span">倍</span>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="attention">
            <span>注意：</span><br />
            <span>1、显示点赞人数=默认基础点赞数+实际点赞数 × 倍数</span><br />
            <span>2、该数据只为前端数据展示，后台数据以真实数据统计</span>
          </div>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="yh_submit">
              <el-button class="btn btn_blue" @click="plugFlowSubmit('form')"
                >提交</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="formright">
      <div class="title">预览效果</div>
      <div class="preview">
        <img
          :src="require('@/assets/img/backStageImg/likepreview-icon.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/apis/backStageEndAPI/myCourseAPI'
import { LIKE_MULTIPLE } from "@/utils/enum";
export default {
  name: "likeSettings",
  props: ['datas','liveInfo'],
  data() {
    return {
      formData: {
        courseId: '',
        courseType: 1,//直播
        num: "",
        times: "",
        liveInfo: {
          liveZanState: '',
          liveZanShow: "",
          liveZanBaseNum: "",
          liveZanBaseTimes: "",
        }
      },
      rules: {},
      isDisabled: false,
    };
  },
  watch: {
    datas: {
      immediate:true,
      handler(val) {
        if(val) {
          this.formData.courseId = val?.courseId
        }
      },
    },
    liveInfo: {
      handler(val) {
        if(val) {
          this.formData.liveInfo = Object.assign({} ,val)
          this.formData.liveInfo.liveZanState = `${val.liveZanState}`
          this.formData.num = val.liveZanBaseNum ? true : false
          this.formData.times = val.liveZanBaseTimes ? true : false
        }
      },
      deep: true
    },
  },
  mounted() {},
  computed: {
    LIKE_MULTIPLE() {
      const arr = Array.from(LIKE_MULTIPLE)
        .slice(0, LIKE_MULTIPLE.length)
        .map(([id, label]) => {
          return {
            id,
            label,
          };
        });
      return arr;
    },
  },
  methods: {
    async plugFlowSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = await API.putCourse(this.formData)
          if(data &&!data.status) {
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    channelInputLimit(e) {
      let key = e.key
      if (key === 'e' || key === '.' || key === '-') {
        e.returnValue = false
        return false
      }
        return true
    },
    filterData() {
      let str = this.$refs.searchSelect.$data.selectedLabel;
      this.formData.liveInfo.liveZanBaseTimes = str.replace(/[^0-9]/g,'')
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    width: 110px !important;
  }
  .el-form-item__content {
    padding-left: 111px;
  }
  .el-radio-group {
    display: flex;
    position: absolute;
    left: 24px;
    top: 8px;
  }
  .el-radio {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
  }
  .formItem {
    position: relative;
  }
  .checkbox {
    position: absolute;
    top: 0;
    left: 31px;
  }
  .switch {
    position: absolute;
    top: 10px;
    right: 5px;
  }
  .selet .el-form-item__error {
    padding-left: 23px !important;
    position: absolute;
    top: 53px;
    left: 0;
  }
  .form .el-form-item__error {
    padding-left: 110px;
  }
}

.yh_wrapper {
  display: flex;
  justify-content: space-around;
  padding: 32px 10px;
  background: #fff;
  .formleft {
    min-width: 303px;
    .bg {
      background: #fff;
      padding: 15px 0 0 8px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }
    .bgfff {
      background: #fff;
      padding: 20px 8px 0 8px;
      box-sizing: border-box;
      border-radius: 14px;

    }
    .selet {
      background: #e6edf5;
      margin-bottom: 30px;
      height: 80px;
      padding: 5px;
    }
  }
  .formright {
    width: 859px;
    background: #fff;
    .preview {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 400px;
        max-height: 779px;
      }
    }
  }
  .attention {
    padding: 8px;
    width: 281px;
    height: 130px;
    font-size: 14px;
    color: #7d8592;
    line-height: 26px;
  }
}
.title {
  color: #7d8592;
  font-size: 14px;
  padding-left: 10px;
  background: #fff;
}
.num {
  background: #e6edf5;
  font-size: 14px;
  color: #7d8592;
  padding-left: 10px;
}
.yh_submit {
  margin-top: 50px;
  text-align: right;
}
.dobble{
  position: relative;
  .dobble_span{
    position: absolute;
    top: 0;
    right: -21px;
  }
}
</style>
