<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/myCourses' }"
        ><i class="el-icon-back"></i>返回</el-breadcrumb-item
      >
      <el-breadcrumb-item>观看控制</el-breadcrumb-item>
    </el-breadcrumb>
    <h1>{{ data.courseTitle }}</h1>
    <div class="tabs">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="观看限制" name="first">
          <el-form
            class="form"
            :model="formData.watchLimit"
            :rules="rules"
            ref="watchLimit"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="观看限制">
                  <el-radio-group
                    v-model="formData.watchLimit.courseLimits"
                    :disabled="this.data.state == '已发布'"
                  >
                    <el-radio :label="0">无限制</el-radio>
                    <el-radio :label="1">登录观看</el-radio>
                    <el-radio :label="2">验证码观看</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="9" v-if="formData.watchLimit.courseLimits == '2'">
                <el-form-item label="验证码" prop="courseCode">
                  <!-- :rules="[{required: true, message: '请输入验证码', trigger: 'blur' }]" -->
                  <el-input
                    v-model="formData.watchLimit.courseCode"
                    maxlength="10"
                    show-word-limit
                  ></el-input>
                  <!-- placeholder="6位数字或字母" -->
                </el-form-item>
              </el-col>
            </el-row>
            <div class="wxq_submit">
              <el-button
                class="btn btn_blue"
                @click="watchLimitSubmit('watchLimit')"
                v-if="
                  data.state == '已下架' ||
                  data.state == '已驳回' ||
                  data.state == '未发布'
                "
                >提交</el-button
              >
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="推流拉流" name="second" v-if="courseType == 1">
          <el-form class="form wxq_form">
            <el-row>
              <el-col :span="13">
                <el-form-item label="推流地址">
                  <span class="wxq_copy color_blue" @click="getCopy"
                    >一键复制</span
                  >
                  <el-input
                    type="textarea"
                    v-model="formData.plugFlow.liveInfo.livePush"
                    resize="none"
                    placeholder="暂无"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item label="拉流地址(RTMP格式)">
                  <el-input
                    type="textarea"
                    v-model="formData.plugFlow.liveInfo.livePullRtmp"
                    resize="none"
                    placeholder="暂无"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item label="拉流地址(FLV格式)">
                  <el-input
                    type="textarea"
                    v-model="formData.plugFlow.liveInfo.livePullFlv"
                    resize="none"
                    placeholder="暂无"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item label="拉流地址(M3U8格式)">
                  <el-input
                    type="textarea"
                    v-model="formData.plugFlow.liveInfo.livePullM3u8"
                    resize="none"
                    placeholder="暂无"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="13">
                <el-form-item label="拉流地址(UDP格式)">
                  <el-input
                    type="textarea"
                    v-model="formData.plugFlow.liveInfo.livePullUdp"
                    resize="none"
                    placeholder="暂无"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div
              class="wxq_submit"
              v-if="
                data.state == '已下架' ||
                data.state == '已驳回' ||
                data.state == '未发布'
              "
            >
              <el-button
                class="btn btn_blue"
                :disabled="isDisabled"
                @click="plugFlowSubmit"
                >提交</el-button
              >
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="点赞设置" name="third" v-if="data.courseType == 1">
          <like-settings :datas="data" :liveInfo="liveInfo" />
        </el-tab-pane>
        <el-tab-pane
          label="观众人数显示设置"
          name="four"
          v-if="data.courseType == 1"
        >
          <audience-setting :datas="data" :liveInfo="liveInfo" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import likeSettings from "./component/likeSettings.vue";
import audienceSetting from "./component/audienceSetting.vue";
import API from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "watchTheControl",
  components: {
    headerBar,
    likeSettings,
    audienceSetting,
  },
  data() {
    return {
      data: "",
      formData: {
        watchLimit: {
          //观看限制
          courseId: "", //课程ID
          courseLimits: "", //观看限制：0|无限制，1|须登录，2|需验证码,
          courseCode: "", //课程验证码
        },
        plugFlow: {
          //推流拉流
          courseId: "", //课程ID
          liveInfo: {
            livePush: "", //推流地址
            livePullRtmp: "", //拉流地址RTMP格式
            livePullFlv: "", //拉流地址FLV格式
            livePullM3u8: "", //拉流地址M3U8格式
            livePullUdp: "", //拉流地址UDP格式
          },
        },
      },
      liveInfo: {},
      activeName: "first",
      courseType: null,
      rules: {
        courseCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    isDisabled() {
      //判断推拉流输入框有无值，全部无值提交按钮禁用，有一项有值就可提交
      let arr = Object.values(this.formData.plugFlow.liveInfo).filter(function (
        item
      ) {
        if (item !== "") {
          return true;
        }
      });
      return arr.length === 0;
    },
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data);
    this.courseType = this.$route.query.cType;
    this.tabLocal();
    this.getDetail();
  },
  methods: {
    getDetail() {
      //获取详情
      API.getCourseDetail(this.data.courseId).then((res) => {
        this.formData.watchLimit.courseLimits = res.courseLimits;
        this.formData.watchLimit.courseCode = res.courseCode;
        this.formData.plugFlow.liveInfo = res.liveInfo;
        this.liveInfo = Object.assign({}, res.liveInfo);
      });
    },
    plugFlowSubmit() {
      //推流拉流提交
      this.formData.plugFlow.courseId = this.data.courseId;
      API.updateCourse(this.formData.plugFlow).then((res) => {});
    },
    watchLimitSubmit(formName) {
      //观看限制提交
      this.formData.watchLimit.courseId = this.data.courseId;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API.updateCourse(this.formData.watchLimit).then((res) => {});
        } else {
          return false;
        }
      });
    },
    getCopy() {
      //一键复制
      let input = document.createElement("textarea"); // 直接构建input
      input.value =
        "推流地址：" +
        this.formData.plugFlow.liveInfo.livePush +
        "\n" +
        "拉流地址RTMP格式：" +
        this.formData.plugFlow.liveInfo.livePullRtmp +
        "\n" +
        "拉流地址FLV格式：" +
        this.formData.plugFlow.liveInfo.livePullFlv +
        "\n" +
        "拉流地址M3U8格式：" +
        this.formData.plugFlow.liveInfo.livePullM3u8 +
        "\n" +
        "拉流地址UDP格式：" +
        this.formData.plugFlow.liveInfo.livePullUdp; // 设置内容 需要复制的内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      if (
        this.formData.plugFlow.liveInfo.livePush === "" &&
        this.formData.plugFlow.liveInfo.livePullRtmp === "" &&
        this.formData.plugFlow.liveInfo.livePullFlv === "" &&
        this.formData.plugFlow.liveInfo.livePullM3u8 === "" &&
        this.formData.plugFlow.liveInfo.livePullUdp === ""
      ) {
        this.$message.warning("值为空,无法复制");
      } else {
        this.$message.success("复制成功");
      }
    },
    tabLocal() {
      //获取本地tab保证刷新页面不返回第一个tab
      if (sessionStorage.getItem("task") != null) {
        this.activeName = sessionStorage.getItem("task");
      }
    },
    handleClick(tab) {
      //点击tab存储在本地
      sessionStorage.setItem("task", tab.name);
    },
  },
  destroyed() {
    sessionStorage.removeItem("task");
  },
};
</script>

<style scoped lang="scss">
.container {
  ::v-deep .is-disabled {
    background: #ffffff !important;
  }
  ::v-deep {
    .el-tabs__item {
      padding: 0 13px;
    }
  }
  .wxq_header {
    margin-bottom: 0;
  }
  h1 {
    margin: 16px 0 16px 6px;
    color: #0a1629;
    font-weight: 800;
    font-size: 24px;
  }
  .el-breadcrumb__item {
    font-size: 16px;
    ::v-deep .is-link {
      color: var(--colorBlue);
      .el-icon-back {
        margin-right: 12px;
      }
    }
  }
  ::v-deep .wxq_form {
    position: relative;
    .wxq_copy {
      position: absolute;
      right: 0;
      bottom: 36px;
      text-decoration: underline;
      cursor: pointer;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__content {
      margin-top: 38px;
      // padding-left: 15px;
      // width: 85%;
    }
    .el-form-item__label {
      margin-top: 38px;
    }
    .el-textarea__inner {
      padding: 0 15px;
      height: 40px;
      border-radius: 8px;
      line-height: 38px;
    }
  }
  ::v-deep .el-form-item__label {
    width: 95px;
  }
  .wxq_submit {
    margin-top: 50px;
    width: 568px;
    text-align: center;
  }
  .is-disabled {
    background: #ced5e0;
  }
}
::v-deep .el-textarea__inner {
  padding: 0;
}
.el-radio {
  padding-right: 14px;
  height: 40px;
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.22);
  line-height: 40px;
  ::v-deep .el-radio__input {
    margin-left: 14px;
    vertical-align: text-bottom;
    .el-radio__inner {
      width: 16px;
      height: 16px;
    }
  }
  ::v-deep .el-radio__label {
    font-size: 16px;
  }
}
</style>
